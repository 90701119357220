import React from "react"

import Layout from "../components/base/layout"
import SEO from "../components/base/seo"

const NotFoundPage = () => (
  <Layout type="template-default page-404">
    <SEO title="404: Not found" />
    <div className="container">
        <section className="section-intro">
            <h1 className="title">NOT FOUND</h1>
            <p className="description">You just hit a route that doesn&#39;t exist... the sadness.</p>
        </section>
    </div>
  </Layout>
);

export default NotFoundPage
